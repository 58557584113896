#login-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .sugarbook-logo-container {
            padding-bottom: 50px;
            text-align: center;

            .sugarbook-logo {}
        }

        .email-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .email-form-field {
                width: 100%;
            }
        }

        .password-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .password-form-field {
                width: 100%;
            }
        }

        .forgot-password-button {
            padding-bottom: 20px;
            text-align: right;
            color: rgb(124, 124, 124);
        }

        .recaptcha-container {
            padding: 15px 0;
            display: flex;
            justify-content: center;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .login-button {
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgb(113, 13, 13);
                border-radius: 25px;
                text-transform: none;
                font-size: 1.1em;
                font-weight: 500;
                background-color: rgb(113, 13, 13);
                color: white;
                transition: all 1s;

                .button-spinner {
                    color: white;
                }
            }

            .facebook-login-button-container {
                display: flex;
                justify-content: center;

                .facebook-logo-container {

                    .facebook-logo {}
                }

                .facebook-login-label {
                    padding-left: 10px;
                    font-size: 1.1em;
                    font-weight: 500;
                }
            }

            .join-button-container {

                .join-button {
                    height: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid rgb(113, 13, 13);
                    border-radius: 25px;
                    text-decoration: none;
                    font-size: 20px;
                    font-weight: 500;
                    color: rgb(113, 13, 13);
                }

            }

            .create-account-label {
                text-align: center;
                color: #7c7c7c;
            }
        }
    }

    // Utility CSS
    .disabled-button {
        border: 2px solid transparent !important;
        cursor: not-allowed !important;
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
    }

    .inactive-button {
        background-color: rgba(0, 0, 0, .12) !important;
        border-color: transparent !important;
        color: rgba(0, 0, 0, .26) !important;
    }

    .active-button {
        background-color: rgb(113, 13, 13) !important;
        border-color: rgb(113, 13, 13) !important;
        color: white !important;
    }
}