$main-color: #710D10;
$text-color: #7D7D7D;

#about-us-page {
    height: 100%;
    background: linear-gradient(to bottom left, #e7e7e7 0%, #ffffff 100%);

    .top-container {
        height: calc(100% - 100px);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;

        .max-width-container {
            height: 300px;
            max-width: 600px;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .header {
                padding-top: 20px;
                font-size: 35px;
                font-weight: 500;
                color: $main-color;
            }

            .text {
                padding-top: 20px;
                color: $text-color;
            }

            .button-container {
                text-decoration: none;

                .button {
                    margin-top: 20px;
                    cursor: pointer;
                    font-weight: 500;
                    color: $main-color;
                }
            }
        }
    }
}