$main-color: #710D10;

#landing-multi-device-subcomponent {
    margin-top: 50px;
    // background: url('../../../assets/others/stockphotos/multi-device-desktop.png');
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center;

    .header {
        max-width: 530px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        color: $main-color;
    }

    .desktop-img {
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .mobile-img {
        width: 100%;
        display: none;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

// Responsive Design
@media(max-width: 600px) {
    #landing-multi-device-subcomponent {
        // background: url('../../../assets/others/stockphotos/multi-device-mobile.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        // background-position: center;

        .header {
            width: 90%;
        }

        .desktop-img {
            display: none;
        }

        .mobile-img {
            display: block;
        }
    }
}