$main-color: #710D10;
$text-color: #7D7D7D;

#terms-page {
    height: 100%;
    background: linear-gradient(to bottom left, #e7e7e7 0%, #ffffff 100%);

    .top-container {
        height: calc(100% - 100px);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;

        .max-width-container {
            height: 90%;
            max-width: 600px;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .header {
                font-size: 35px;
                font-weight: 500;
                color: $main-color;
            }

            .terms {
                overflow: auto;

                .terms-header {
                    padding-bottom: 20px;
                    font-size: 20px;
                    font-weight: 600;
                }

                .terms-subheader {
                    padding-bottom: 15px;
                    line-height: 1.5;
                }

                .terms-point {
                    padding-bottom: 5px;
                }
            }

            .button {
                cursor: pointer;
                font-weight: 500;
                color: $main-color;
            }
        }
    }
}