#info-signup-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .started-label {
            padding-bottom: 50px;
            text-align: center;
            font-size: 26px;
            font-weight: 700;
        }

        .profile-img-container {
            margin-bottom: 20px;
            display: flex;
            justify-content: center;

            .profile-img {}
        }

        .profile-img-button {
            margin-bottom: 20px;
            display: flex;
            justify-content: center;

            .profile-img {
                height: 133px;
                width: 133px;
                border-radius: 70px;
                object-fit: cover;
            }
        }

        .description {
            margin-bottom: 40px;
            text-align: center;
        }

        .name-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .name-form-field {
                width: 100%;
            }
        }

        .dob-form-field-container {
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .dob-form-field {
                width: 100%;
            }
        }

        .location-form-field-container {
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .location-form-field {
                width: 100%;
            }
        }

        .continue-button {
            height: 47px;
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-decoration: none;
            text-transform: none;
            cursor: pointer;
            font-size: 20px;
            font-weight: 500;
            background-color: rgb(113, 13, 13);
            color: white;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }

        // .button-container {
        //     display: flex;
        //     flex-direction: column;
        //     gap: 20px;

        //     .continue-button {
        //         height: 45px;
        //         margin-top: 25px;
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         border: 1px solid rgb(113, 13, 13);
        //         border-radius: 25px;
        //         text-decoration: none;
        //         font-size: 1.1em;
        //         font-weight: 500;
        //         color: rgb(113, 13, 13);
        //     }
        // }
    }
}