$main-color: #710d0d;

#App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: Lato;

  .body-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }
}

// Input colors to main color
.custom-text-field {

  .MuiInput-underline:after {
    border-bottom-color: $main-color !important;
  }

  label.Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}