#description-signup-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .steps-label {
            margin-bottom: 20px;
            text-align: center;
            font-size: 16px;
            color: #7c7c7c;
        }

        .step-description-label {
            padding-bottom: 20px;
            text-align: center;
            font-size: 26px;
            font-weight: 700;
        }

        .about-me-form-field-container {
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .about-me-form-field {
                width: 100%;
            }
        }

        .looking-for-form-field-container {
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .looking-for-form-field {
                width: 100%;
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .next-button-container {

                .next-button {
                    height: 45px;
                    margin-top: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid rgb(113, 13, 13);
                    border-radius: 25px;
                    text-decoration: none;
                    font-size: 1.1em;
                    font-weight: 500;
                    color: rgb(113, 13, 13);
                }
            }

            .skip-button-container {
                text-align: center;

                .skip-button {
                    text-decoration: none;
                    color: rgb(124, 124, 124);
                }
            }
        }
    }
}