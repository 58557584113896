$main-color: #710D0D;

#footer {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-color;

    .text {
        color: white;
    }
}