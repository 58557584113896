$main-color: #710D10;

#error-page {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .error-label {
        font-size: 40px;
        font-weight: 500;
        color: $main-color
    }
}