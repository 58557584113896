$main-color: #710D10;

#redirect-page {
    height: 100%;
    display: flex;
    justify-content: center;

    .center-container {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .header {
            padding-bottom: 20px;
            font-size: 40px;
            font-weight: 500;
            color: $main-color;
        }

        .text {}
    }
}