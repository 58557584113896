#join-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .header {
            padding-bottom: 25px;
            font-size: 18px;
            font-weight: 400;
        }

        .body-container {
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 10px;

            .body-label {
                height: 45px;
                width: 50%;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #ccc;
                border-radius: 25px;
                cursor: pointer;
            }
        }

        .continue-button {
            height: 47px;
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-decoration: none;
            text-transform: none;
            cursor: pointer;
            font-size: 20px;
            font-weight: 500;
            background-color: rgb(113, 13, 13);
            color: white;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }
    }

    // Utility CSS
    .selected {
        border: 3px solid #710d0d !important;
        color: #710d0d !important;
    }
}

// Responsive Design
@media (max-width: 768px) {
    #join-page {

        .max-width-container {
            flex-direction: column;

            .body-container {
                flex-direction: column;
                align-items: center;
                gap: 0;

                .body-label {
                    width: calc(100% - 6px);
                }
            }
        }
    }
}