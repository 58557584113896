#lifestyle-signup-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .steps-label {
            margin-bottom: 20px;
            text-align: center;
            font-size: 16px;
            color: #7c7c7c;
        }

        .step-description-label {
            padding-bottom: 20px;
            text-align: center;
            font-size: 26px;
            font-weight: 700;
        }

        .flex-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .monthly-habit-form-field-container {
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .monthly-habit-form-field {
                width: 100%;
            }
        }

        .net-worth-form-field-container {
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .net-worth-form-field {
                width: 100%;
            }
        }

        .annual-income-form-field-container {
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .annual-income-form-field {
                width: 100%;
            }
        }

        .height-form-field-container {
            width: 45%;
            padding-bottom: 30px;

            .height-form-field {
                width: 100%;
            }
        }

        .body-type-form-field-container {
            width: 45%;
            padding-bottom: 30px;

            .body-type-form-field {
                width: 100%;
            }
        }

        .ethnicity-form-field-container {
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .ethnicity-form-field {
                width: 100%;
            }
        }

        .eye-color-form-field-container {
            width: 45%;
            padding-bottom: 30px;

            .eye-color-form-field {
                width: 100%;
            }
        }

        .hair-color-form-field-container {
            width: 45%;
            padding-bottom: 30px;

            .hair-color-form-field {
                width: 100%;
            }
        }

        .button-container {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .next-button-container {

                .next-button {
                    height: 45px;
                    margin-top: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid rgb(113, 13, 13);
                    border-radius: 25px;
                    text-decoration: none;
                    font-size: 1.1em;
                    font-weight: 500;
                    color: rgb(113, 13, 13);
                }
            }

            .skip-button-container {
                text-align: center;

                .skip-button {
                    text-decoration: none;
                    color: rgb(124, 124, 124);
                }
            }
        }
    }
}

// Responsive Design
@media (max-height: 780px) {
    #lifestyle-signup-page {

        .max-width-container {
            justify-content: unset;
        }
    }
}