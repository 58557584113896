#landing-feature-in-subcomponent {

    .max-width-container {
        max-width: 1250px;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-left: auto;
        margin-right: auto;

        .feature-label-container {
            padding-bottom: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            .feature-label {
                width: 40%;
                min-width: 300px;
                padding: 5px;
                font-size: 20px;
                font-weight: 700;
                color: #555;
            }

            .feature-label::before,
            .feature-label::after {
                border-top: 3px solid #BCBCBC;
            }

            .lines {}
        }

        .featured-company-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            .company-logo-container {
                width: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;

                img {
                    width: 100%;
                    max-width: 140px;
                }
            }
        }
    }
}