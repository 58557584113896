#landing-hero-subcomponent {
    height: calc(100vh - 100px); // 100px - navbar
    position: relative;

    .hero-media-container {
        height: 100%;
        width: 100%;

        .hero-image {
            height: 100%;
            width: 100%;
            display: none;
            object-fit: cover;
        }

        .hero-video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .hero-description-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;

        .mobile-center-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .text {
                font-size: 25px;
                text-align: center;
                color: white;
            }

            .welcome {
                padding-bottom: 15px;
                display: none;
            }

            .premier-1 {
                padding-top: 25px;
            }

            .journey {
                padding-top: 5px;
                padding-bottom: 40px;
            }

            .brand-logo-container {
                display: none;

                .brand-logo {
                    max-width: 300px;
                }
            }

            .button-container {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .join-button-container {
                    text-decoration: none;

                    .join-button {
                        height: 50px;
                        width: 200px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50px;
                        font-size: 20px;
                        font-weight: 600;
                        background-color: #710D10;
                        color: white;
                    }
                }

                .login-button-container {
                    text-decoration: none;

                    .login-button {
                        height: 50px;
                        width: 200px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50px;
                        font-size: 20px;
                        font-weight: 600;
                        background-color: #710D10;
                        color: white;
                    }
                }
            }
        }
    }
}

// Responsive Design
@media (max-width: 680px) {
    #landing-hero-subcomponent {
        flex-direction: column;

        .hero-media-container {
            height: 50%;
            width: 100%;

            .hero-image {
                display: block;
            }

            .hero-video {
                display: none;
            }
        }

        .hero-description-container {
            height: 50%;
            width: 100%;
            position: relative;
            align-items: center;

            .mobile-center-container {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                align-items: center;

                .text {
                    font-size: 15px;
                    text-align: left;
                    color: #7D7D7D;
                }

                .button-container {
                    flex-direction: column;

                    .join-button {
                        height: 60px;
                        width: 100%;
                        font-size: 20px;
                    }

                    .login-button {
                        height: 60px;
                        width: 100%;
                        font-size: 20px;
                    }
                }

                .welcome {
                    display: block;
                }

                .brand-logo-container {
                    display: block;
                }

                .journey {
                    text-align: center;
                }
            }
        }
    }
}