$main-color: #710D10;

#landing-join-now-subcomponent {

    .gradient-background {
        margin-top: 50px;
        padding-bottom: 50px;
        display: flex;
        background: linear-gradient(to bottom, #e7e7e7 -35%, #ffffff 99%);

        .image-container {
            width: 45%;
            transform: translateY(-40px);

            .image {
                width: 100%;
            }
        }

        .join-now-description-container {
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;

            .header {
                max-width: 400px;
                padding-bottom: 40px;
                font-size: 35px;
                font-weight: 600;
                color: $main-color;
            }

            .join-now-button-container {
                text-decoration: none;

                .join-now-button {
                    height: 50px;
                    width: 250px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50px;
                    font-weight: 600;
                    background-color: $main-color;
                    color: white;
                }
            }
        }
    }
}

// Responsive Design
@media (max-width: 800px) {
    #landing-join-now-subcomponent {

        .gradient-background {
            flex-direction: column;

            .image-container {
                width: 80%;

                .image {
                    width: 100%;
                }
            }

            .join-now-description-container {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                padding-left: 0px;
                padding-right: 0px;
                padding-bottom: 50px;
                align-items: center;
                text-align: center;

                .header {
                    max-width: 100%;
                    padding-bottom: 20px;
                    font-size: 30px;
                    font-weight: 600;
                    color: $main-color;
                }

                .join-now-button {
                    width: 100%;
                }
            }
        }
    }
}